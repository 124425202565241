export const RESTFUL = {
  clockInRecords: {
    list: 'toca/clockInRecord/list',
    read: 'toca/admin/clockInRecords',
    create: 'toca/admin/clockInRecords',
  },
  user: {
    list: 'users',
    read: 'users',
    create: 'users',
  },
  profile: {
    list: 'profiles',
    read: 'toca/admin/profiles',
    create: 'toca/admin/profiles',
    cashierCreate: 'toca/cashier/profiles',
    cashierList: 'toca/cashier/profiles',
    masterCreate: 'toca/master/profiles',
    masterRead: 'toca/master/profiles',
  },
  clientProfile: {
    list: 'toca/admin/clientProfiles',
    read: 'toca/admin/clientProfiles',
    create: 'toca/admin/clientProfiles',
    masterList: 'toca/master/clientProfiles',
  },
  masterProfile: {
    list: 'toca/admin/MasterProfiles',
    read: 'toca/admin/MasterProfiles',
    create: 'toca/admin/MasterProfiles',
  },
  serviceProducts: {
    list: 'toca/admin/serviceProducts',
    read: 'toca/admin/serviceProducts',
    create: 'toca/admin/serviceProducts',
    masterList: 'toca/master/serviceProducts',
    update: 'toca/admin/serviceProducts',
  },
  productCategories: {
    list: 'toca/admin/productCategories',
    read: 'toca/admin/productCategories',
    create: 'toca/admin/productCategories',
    masterList: 'toca/master/productCategories',
  },
  leaveApplication: {
    list: 'toca/admin/leaveApplications',
    read: 'toca/admin/leaveApplications',
    create: 'toca/admin/leaveApplications',
    masterList: 'toca/master/leaveApplications',
  },
  booking: {
    list: 'toca/admin/bookings',
    read: 'toca/admin/bookings',
    create: 'toca/admin/bookings',
    masterList: 'toca/master/bookings',
  },
  timetable: {
    list: 'toca/admin/timetables',
    read: 'toca/admin/timetables',
    create: 'toca/admin/timetables',
  },
  transaction: {
    list: 'toca/admin/transactions',
    read: 'ec/transactions',
    create: 'ec/transaction',
  },
  physicalProducts: {
    list: 'toca/admin/PhysicalProducts',
    read: 'toca/admin/PhysicalProducts',
    create: 'toca/admin/PhysicalProducts',
    masterList: 'toca/master/PhysicalProducts',
  },
  extensionProducts: {
    list: 'toca/admin/ExtensionProducts',
    read: 'toca/admin/ExtensionProducts',
    create: 'toca/admin/ExtensionProducts',
    masterList: 'toca/master/ExtensionProducts',
  },
  restaurantProducts: {
    list: 'toca/admin/RestaurantProducts',
    read: 'toca/admin/RestaurantProducts',
    create: 'toca/admin/RestaurantProducts',
    masterList: 'toca/master/RestaurantProducts',
  },
  physicalProductOrders: {
    list: 'toca/admin/ProductOrders',
    read: 'toca/admin/ProductOrders',
    create: 'toca/admin/ProductOrders',
  },
  EmployeeContract: {
    list: 'toca/admin/employeeContracts',
    read: 'toca/admin/employeeContracts',
    create: 'toca/admin/employeeContracts',
    masterList: 'toca/master/employeeContracts',
    masterRead: 'toca/master/employeeContracts',
  },
  teachingTasks: {
    list: 'toca/admin/teachingTasks',
    read: 'toca/admin/teachingTasks',
    create: 'toca/admin/teachingTasks',
  },
  payrollRecords: {
    list: 'toca/admin/payrollRecords',
    read: 'toca/admin/payrollRecords',
    create: 'toca/admin/payrollRecords',
    masterList: 'toca/master/payrollRecords',
    masterRead: 'toca/master/payrollRecords',
    update: 'toca/admin/payrollRecords',
  },
  cooperateCalendarEvents: {
    list: 'toca/admin/cooperateCalendarEvents',
    read: 'toca/admin/cooperateCalendarEvents',
    create: 'toca/admin/cooperateCalendarEvents',
  },
  vipProducts: {
    list: 'toca/admin/VIPProducts',
    read: 'toca/admin/VIPProducts',
    create: 'toca/admin/VIPProducts',
  },
  vipProductPurchaseRecords: {
    list: 'toca/admin/VIPProductPurchaseRecords',
    read: 'toca/admin/VIPProductPurchaseRecords',
    create: 'toca/admin/VIPProductPurchaseRecords',
  },
  vipActionLogs: {
    list: 'toca/admin/VIPActionLogs',
  },
  creditRecord: {
    list: 'toca/admin/CreditRecords',
    read: 'toca/admin/CreditRecords',
    create: 'toca/admin/CreditRecords',
    update: 'toca/admin/CreditRecords',
  },
  checkInRecord: {
    list: 'toca/checkInRecord/list',
  },
  stores: {
    list: 'toca/admin/stores',
    read: 'toca/admin/stores',
    create: 'toca/admin/stores',
    update: 'toca/admin/stores',
  },
  warehouses: {
    list: 'toca/admin/warehouses',
    read: 'toca/admin/warehouses',
    create: 'toca/admin/warehouses',
    update: 'toca/admin/warehouses',
  },
  stockCountRecord: {
    list: 'toca/admin/StockCountRecords',
    read: 'toca/admin/StockCountRecords',
    create: 'toca/admin/StockCountRecords',
    update: 'toca/admin/StockCountRecords',
  },
  suppliers: {
    list: 'toca/suppliers',
    read: 'toca/suppliers',
    create: 'toca/suppliers',
    update: 'toca/suppliers',
  },
  purchaseOrders: {
    list: 'toca/purchase-orders',
    read: 'toca/purchase-orders',
    create: 'toca/purchase-orders',
    update: 'toca/purchase-orders',
    updateStatus: 'toca/purchase-order/updateStatus',
  },
  storeUsers: {
    list: 'toca/admin/store-users',
    read: 'toca/admin/store-users',
    create: 'toca/admin/store-users',
    update: 'toca/admin/store-users',
  },
  productStores: {
    list: 'toca/admin/product-stores',
    read: 'toca/admin/product-stores',
    create: 'toca/admin/product-stores',
    update: 'toca/admin/product-stores',
  },
  warehouseStocks: {
    list: 'toca/admin/warehouse-stocks',
    read: 'toca/admin/warehouse-stocks',
    create: 'toca/admin/warehouse-stocks',
    update: 'toca/admin/warehouse-stocks',
  },
  discounts: {
    list: 'toca/admin/discounts',
    read: 'toca/admin/discounts',
    create: 'toca/admin/discounts',
    update: 'toca/admin/discounts',
  },
  transfers: {
    list: 'toca/transfers',
    read: 'toca/transfers',
    create: 'toca/transfers',
    update: 'toca/transfers',
    readByPosOrderId: 'toca/admin/transfer/posOrder',
  },
  productActivityLog: {
    list: 'toca/admin/ProductActivityLogs',
    read: 'toca/admin/ProductActivityLogs',
    create: 'toca/admin/ProductActivityLogs',
    update: 'toca/admin/ProductActivityLogs',
  },
};

export const roles = {
  admin: 'admin',
  client: 'toca.client',
  master: 'toca.master',
  cashier: 'toca.cashier',
};

export const management = {
  facility: 'facility',
  coach: 'coach',
};

export const ServiceProductSessionType = {
  entranceOnly: 'entranceOnly',
  groupClass: 'groupClass',
  privateTraining: 'privateTraining',
};

export const ServiceProductGameType = {
  ski: 'ski',
  snowboard: 'snowboard',
  groundTricks: 'groundTricks',
  jibbing: 'jibbing',
  flowboarding: 'flowboarding',
};

export const paymentOfCreditCard = {
  ae: 'AE',
  visa: 'Visa',
  master: 'Master',
};

export const paymentOfOthers = {
  cash: 'Cash',
  octopusCard: 'Octopus Card',
  unionPay: 'Union Pay',
  payMe: 'Payme',
  payPal: 'Paypal',
  alipayHK: 'Alipay HK',
  bankTransfer: 'Bank Transfer',
  cheque: 'Cheque',
};

export const physicalProductOrderStatusTextTranslation = {
  pendingPayment: 'Pending Payment',
  pendingDelivery: 'Pending Delivery',
  completed: 'Completed',
  cancelled: 'Cancelled',
  expired: 'Expired',
};

export const leaveApplicationOption = {
  ByDate: 'ByDate',
  ByHour: 'ByHour',
};
export const WORKING_HOURS_PER_DAY = 9;

export const payrollConstant = [
  {
    name: '___basicSalary_of_activatedContract___',

    description: 'The basic salary of activated contract ',
  },
  {
    name: '___diligenceAward_of_activatedContract___',
    description: 'The diligence award of activated contract',
  },
  {
    name: '___yearBonus_of_activatedContract___',
    description: 'The year bonus of activated contract',
  },
  {
    name: '___MPFRate_of_activatedContract___',
    description: 'The MPF Rate of activated contract',
  },
  {
    name: '___annualLeave_selectedMonth_remaining_of_activatedContract___',
    description: 'The annual leave selected month remaining of activated contract',
  },
  {
    name: '___sickLeave_selectedMonth_remaining_of_activatedContract___',
    description: 'The sick leave selected month remaining of activated contract',
  },
  {
    name: '___compensatoryLeave_selectedMonth_remaining_of_activatedContract___',
    description: 'The compensatory leave selected month remaining of activated contract',
  },
  {
    name: '___noPayLeave_selectedMonth_remaining_of_activatedContract___',
    description: 'The no pay leave selected month remaining of activated contract',
  },

  {
    name: '___servicePrice_for_seniority_of_activatedContract_n1___',
    description: 'The service price for seniority of activated contract n1',
  },
  {
    name: '___servicePrice_for_seniority_of_activatedContract_n2___',
    description: 'The service price for seniority of activated contract n2',
  },
  {
    name: '___servicePrice_for_seniority_of_activatedContract_n3___',
    description: 'The service price for seniority of activated contract n3',
  },
  {
    name: '___servicePrice_for_seniority_of_activatedContract_n4___',
    description: 'The service price for seniority of activated contract n4',
  },
  {
    name: '___servicePrice_for_seniority_of_activatedContract_n5___',
    description: 'The service price for seniority of activated contract n5',
  },

  {
    name: '___cashierFeeRate___',
    description: 'The cashier fee rate',
  },
  {
    name: '___specificMasterFee___',
    description: 'The specific therapist fee',
  },

  {
    name: '___trainingFee_firstMonth___',
    description: 'The training fee first month',
  },
  {
    name: '___trainingFee_afterFirstMonth___',
    description: 'The training fee after first month',
  },

  {
    name: '___headcount_selectedMonth___',
    description: 'The headcount selected month',
  },
  {
    name: '___headcount_selectedMonth_assist___',
    description: 'The headcount selected month assist',
  },
  {
    name: '___headcount_selectedMonth_assist_n1___',
    description: 'The headcount selected month assist n1',
  },
  {
    name: '___headcount_selectedMonth_assist_n2___',
    description: 'The headcount selected month assist n2',
  },
  {
    name: '___headcount_selectedMonth_assist_n3___',
    description: 'The headcount selected month assist n3 ',
  },
  {
    name: '___headcount_selectedMonth_assist_n4___',
    description: 'The headcount selected month assist n4',
  },
  {
    name: '___headcount_selectedMonth_assist_n5___',
    description: 'The headcount selected month assist n5',
  },

  {
    name: '___headcount_selectedMonth_main___',
    description: 'The headcounts selected month main',
  },
  {
    name: '___headcount_selectedMonth_main_n1___',
    description: 'The headcounts selected month main n1',
  },
  {
    name: '___headcount_selectedMonth_main_n2___',
    description: 'The headcounts selected month main n2',
  },
  {
    name: '___headcount_selectedMonth_main_n3___',
    description: 'The headcounts selected month main n3',
  },
  {
    name: '___headcount_selectedMonth_main_n4___',
    description: 'The headcounts selected month main n4',
  },
  {
    name: '___headcount_selectedMonth_main_n5___',
    description: 'The headcounts selected month main n5',
  },

  {
    name: '___headcount_selectedMonth_total___',
    description: 'The headcount selected month total',
  },
  {
    name: '___headcount_selectedMonth_total_n1___',
    description: 'The headcount selected month total n1',
  },
  {
    name: '___headcount_selectedMonth_total_n2___',
    description: 'The headcount selected month total n2',
  },
  {
    name: '___headcount_selectedMonth_total_n3___',
    description: 'The headcount selected month total n3',
  },
  {
    name: '___headcount_selectedMonth_total_n4___',
    description: 'The headcount selected month total n4',
  },
  {
    name: '___headcount_selectedMonth_total_n5___',
    description: 'The headcount selected month total n5',
  },

  {
    name: '___studentCount_selectedMonth_firstMonth_activatedContract___',
    description: 'The student count selected month first month activated contract',
  },
  {
    name: '___studentCount_selectedMonth_secondAndThirdMonth_activatedContract___',
    description: 'The student count selected month second and third month activated contract',
  },
  {
    name: '___studentCount_selectedMonth_total_activatedContract___',
    description: 'The student count selectedMonth_total_activatedContract',
  },
  {
    name: '___ticketPackageSoldCommission_selectedMonth_total___',
    description: 'The ticket package sold commission selected month_total',
  },
  {
    name: '___physicalProductSoldCommission_selectedMonth_total___',
    description: 'The physical product sold commission selected month total',
  },
];

export const positions = {
  customer_service_officer: 'Customer Service Officer',
  hair_therapist: 'Herbal Hair Color Therapist',
  senior_hair_therapist: 'Herbal Hair Color Senior Therapist',
  supervisor: 'Shop Supervisor',
  manager: 'Shop Manager',
  assistant: 'Shop Assistant',
  sales_manager: 'Sales Manager',
  project_manager: 'Project Manager',
  director: 'Director',
  service_provider: 'Service Provider',
  other: 'Other',
};

export const defaultFormulaItems = [
  {
    outputTitle: 'Basic Salary',
    formula: '___basicSalary_of_activatedContract___',
    label: 'Basic Salary',
    remarks: 'default',
  },
  {
    outputTitle: 'Treatment Commission (Seniority)',
    formula:
      '___servicePrice_for_seniority_of_activatedContract_n1___*___headcount_selectedMonth_total_n1___+___servicePrice_for_seniority_of_activatedContract_n2___*___headcount_selectedMonth_total_n2___+___servicePrice_for_seniority_of_activatedContract_n3___*___headcount_selectedMonth_total_n3___+___servicePrice_for_seniority_of_activatedContract_n4___*___headcount_selectedMonth_total_n4___+___servicePrice_for_seniority_of_activatedContract_n5___*___headcount_selectedMonth_total_n5___',
    label: 'Treatment Commission (Seniority)',
    remarks: 'default',
  },
  {
    outputTitle: 'Over 110',
    formula: 'Math.max(___headcount_selectedMonth_total___-110 , 0) *100',
    label: 'Over 110',
    remarks: 'default',
  },
  {
    outputTitle: 'Product Commission',
    formula: '___physicalProductSoldCommission_selectedMonth_total___',
    label: 'Product Commission',
    remarks: 'default',
  },
  {
    outputTitle: 'Package Commission',
    formula: '___ticketPackageSoldCommission_selectedMonth_total___',
    label: 'Product Commission',
    remarks: 'default',
  },
  // {
  //   outputTitle: 'Teacher Fee',
  //   formula: '___trainingFee_firstMonth___ + ___trainingFee_afterFirstMonth___',
  //   label: 'Teacher Fee',
  //   remarks: 'default',
  // },
];
export const transactionTypes = {
  // for meatadata
  TicketsPackage: 'TicketsPackage',
  Booking: 'Booking',
  PhysicalProductOrder: 'PhysicalProductOrder',
  PayrollRecord: 'PayrollRecord',
  computedOnlineOrder: 'computedOnlineOrder',
  computedPurchaseInService: 'computedPurchaseInService',
  restaurantService: 'Restaurant', // TODO to be confirm restaurant data type
  Custom: 'Custom',
};
export const transactionRefs = [
  // for refs
  'TicketsPackage',
  'Booking',
  'ProductOrder',
  'PayrollRecord',
];

export const calendarEventType = {
  default: 'default',
  holiday: 'holiday',
  other: 'other',
};

export const CMSPostType = {
  homePage: 'homePage',
  termsAndConditions: 'page.terms_condition',
  privacyPolicy: 'page.privacyPolicy',
  FAQ: 'page.faq',
  contactUs: 'page.contactUs',
  coach: 'master.coaches',
  homePagePricing: 'homePage.pricing',
  homePageDiscoverClasses: 'homePage.discoverClasses',
};

export const creditRecordActions = {
  topup: 'topup',
  usage: 'usage',
  refund: 'refund',
};

export const restaurantProductRemarks = {
  salt: 'salt',
  sugar: 'sugar',
  oil: 'oil',
  ice: 'ice',
  rice: 'rice',
};

export const stockCountRecordStatus = {
  counting: 'counting',
  completed: 'completed',
};

export const ProductActivityLogUpdateReason = {
  Purchase: 'Purchase',
  PreOrder: 'PreOrder',
  TransferIn: 'TransferIn',
  TransferOut: 'TransferOut',
  DeliveryLocal: 'DeliveryLocal',
  DeliveryMainLand: 'DeliveryMainLand',
  Display: 'Display',
  UnDisplay: 'UnDisplay',
  Hold: 'Hold',
  Unhold: 'Unhold',
  Adjustment: 'Adjustment',
  Loss: 'Loss',
  Defected: 'Defected',
  Broken: 'Broken',
  SkipStock: 'SkipStock',
  Void: 'Void',
  VoidWithoutStockChange: 'VoidWithoutStockChange',
  Unknown: 'Unknown',
  Other: 'Other',
};

export const TransferProblemConfirmedAction = {
  AddedToSender: 'AddedToSender',
  AddedToReceiver: 'AddedToReceiver',
  OnlyConfirmed: 'OnlyConfirmed',
};

export const POSpaymentMethod = {
  FPS: 'fps',
  visa: 'visa',
  master: 'master',
  ae: 'AE',
  unionpay: 'union_pay',
  octopus: 'octopus',
  cash: 'cash',
  alipay: 'alipay',
  alipay_hk: 'alipay_hk',
  external_gateway: 'external_gateway',
  wechat: 'wechat_pay',
  wechat_hk: 'wechat_pay_hk',
  boc: 'boc',
  tap_and_go: 'tap&go',
  unionpay_c: 'union_pay_c',
  googlepay: 'google_pay',
  applepay: 'apple_pay',
  huaweipay: 'huawei_pay',
  samsungpay: 'samsung_pay',
  member_credit: 'member_credit',
};

export const POSTransactionType = {
  income: 'income',
  expense: 'expense',
};

export const ClockInType = {
  clockIn: 'clockIn',
  clockOut: 'clockOut',
};

export const paymentMethodName = {
  waive: 'Waive',
  visa: 'VISA',
  master: 'Master',
  ae: 'AE',
  unionpay: 'UnionPay',
  octopus: 'Octopus',
  cash: 'Cash',
  alipay: 'Alipay',
  alipay_hk: 'Alipay HK',
  external_gateway: 'External Gateway',
  wechat: 'WeChat',
  wechat_hk: 'WeChat HK',
  boc: 'BOC',
  tap_and_go: 'Tap & Go',
  unionpay_c: 'UnionPay C',
  googlepay: 'Google Pay',
  applepay: 'Apple Pay',
  huaweipay: 'HuaWei Pay',
  samsungpay: 'Samsung Pay',
  member_credit: 'Member Credit',
  jcb: 'JCB',
  fps: 'FPS',
  stripe: 'Stripe',
  pos: 'POS',
  'tap&go': 'Tag & Go',
  wechat_pay: 'WeChat Pay',
  wechat_pay_hk: 'WeChat Pay HK',
  discovers_diners: 'Discovers/Diners',
  union_pay: 'UnionPay',
  union_pay_c: 'UnionPay C',
  union_pay_qr: 'UnionPay (QR)',
  samsung_pay: 'Samsung Pay',
  google_pay: 'Google Pay',
  apple_pay: 'Apple Pay',
  huawei_pay: 'HuaWei Pay',
}